<template>
  <div class="d-flex">
    <div
      :class="taskBackgroundClass"
      class="d-flex task flex-grow-1"
      @click.stop="handleTaskClick()"
      @mouseover="setTaskActiveHoverActive()"
      @mouseleave="setTaskActiveHoverInactive()"
    >
      <div class="task-icon-container d-flex align-items-center justify-content-center" :class="iconContainerClass">
        <div v-if="!displayMode && taskActiveHover" class="h-100">
          <EditFillIcon class="task-icon" :fill="taskOverdue ? '#763700' : '#46494F'" />
        </div>
        <div v-else class="h-100">
          <BellUserIcon v-if="taskOverdue && taskSnoozedByUser" class="bell-user-icon" :fill="'#763700'" />
          <BellOutlinesIcon v-else-if="taskOverdue" class="task-icon" :fill="'#763700'" />
          <SnoozeIcon v-else class="task-icon" />
        </div>
      </div>
      <div
        class="task-time-text d-flex align-items-center justify-content-center w-100"
        :class="taskOverdue ? 'text-color-overdue' : 'text-color-snooze'"
      >
        {{ getRelativeFromNowString(currentTime) }}
      </div>
    </div>

    <div @click.stop="">
      <TaskSnoozeModal
        v-if="openSnoozeModal"
        :task="task"
        @update-task="handleTaskUpdate"
        @close="openSnoozeModal = false"
      >
        <template #subtile>
          <slot name="task-snooze-modal-subtitle" />
        </template>
      </TaskSnoozeModal>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { isNil } from 'ramda';
import { DateTime } from 'luxon';
import moment from 'moment';

import { BellOutlinesIcon, EditFillIcon, SnoozeIcon, BellUserIcon } from '@/assets/icons';

import { TaskSnoozeModal } from '../components';

export default {
  components: { BellOutlinesIcon, EditFillIcon, SnoozeIcon, BellUserIcon, TaskSnoozeModal },
  props: {
    task: { type: Object, required: true },
    displayMode: { type: Boolean, required: false },
  },
  setup(props) {
    const computedTask = computed(() => props.task);

    return {
      taskActiveHover: ref(false),
      currentTime: ref(new Date()),
      openSnoozeModal: ref(false),
      taskOverdue: ref(false),
      computedTask,
    };
  },
  computed: {
    computedTime: {
      get() {
        return this.currentTime;
      },
      set(newValue) {
        this.currentTime = newValue;
      },
    },

    taskBackgroundClass() {
      if (this.displayMode) {
        return this.taskOverdue ? 'active-task__background__displayMode' : 'inactive-task__background__displayMode';
      } else {
        return this.taskOverdue ? 'active-task__background' : 'inactive-task__background';
      }
    },
    taskSnoozedByUser() {
      return !isNil(this.task.activeBy);
    },
    iconContainerClass() {
      const taskIconClass = this.taskOverdue ? 'task-icon-border-overdue' : 'task-icon-border-snooze';
      return this.taskActiveHover ? taskIconClass + '-hover' : taskIconClass;
    },
  },
  watch: {
    computedTask: {
      handler() {
        this.updateComputedTime();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.updateTimeInterval = setInterval(this.updateComputedTime, 10000);
  },

  beforeDestroy() {
    clearInterval(this.updateTimeInterval);
  },

  methods: {
    handleTaskUpdate(updateData) {
      this.$emit('updateTask', updateData);
    },
    handleTaskClick() {
      if (!this.displayMode) {
        this.openSnoozeModal = true;
      }
    },
    setTaskActiveHoverActive() {
      this.taskActiveHover = true;
    },
    setTaskActiveHoverInactive() {
      this.taskActiveHover = false;
    },
    getRelativeFromNowString(currentTime) {
      return moment(this.task.activeAt).from(currentTime);
    },
    updateComputedTime() {
      this.computedTime = new Date();
      this.taskOverdue =
        this.task.activeAt && DateTime.fromMillis(this.task.activeAt) <= DateTime.fromJSDate(new Date());
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.task {
  width: 98px;
  height: 24px;
  border-radius: 4px;

  &-icon-container {
    height: 24px;
    width: 24px;
  }
  &-icon {
    width: 24px;
    height: 16px;
  }
  &-time-text {
    font-weight: 500;
    font-size: 10px;
  }
}

.bell-user-icon {
  width: 24px;
  height: 24px;
}

.inactive-task__background {
  background-color: #f3f3f4;
  &__displayMode {
    background-color: #f3f3f4;
  }
  &:hover {
    background-color: #e3e4e6;
  }
}

.active-task__background {
  background-color: #f9f2e8;
  &__displayMode {
    background-color: #f9f2e8;
  }
  &:hover {
    background-color: #f4e2cd;
  }
}

.task-icon-border-overdue {
  border-left: 1px solid #f4e2cd;
  &-hover {
    border-left: 1px solid #edcfac;
  }
}

.task-icon-border-snooze {
  border-left: 1px solid #e3e4e6;
  &-hover {
    border-left: 1px solid #d2d4d7;
  }
}

.text-color-overdue {
  color: #763700;
}
.text-color-snooze {
  color: #46494f;
}
</style>
