<template>
  <div class="row" @click="onClickOutside">
    <SingularTaskLayout
      :title="$t('tasks.allocationTask.header.title')"
      :task="currentTask"
      :is-loading-task="loading"
      :validate-task-before-complete-hook="validateTaskHook"
      :requests-target-businesses-ids="[supplier.id, task.businessId]"
      @complete-task="completedTask"
      @skip-task="skipTask"
    >
      <template #content>
        <div class="details-container p-6 mb-7">
          <div class="d-flex flex-row align-items-center">
            <el-popover :value="contactPopover" trigger="manual" placement="bottom" popper-class="p-0">
              <ContactCard
                v-if="contactUser"
                :user="contactUser"
                :customer="business"
                :supplier="supplier"
                :token="token"
              />
              <template #reference>
                <Button
                  type="link"
                  class="text-typography-primary p-0"
                  @click.stop="openContactPopover({ type: 'tenant', payload: { worksAt: business?.id } })"
                >
                  <h3>{{ business?.name }}</h3>
                </Button>
                <ArrowLeftIcon class="arrow-left-icon" />
                <Button
                  type="link"
                  class="text-typography-primary p-0"
                  @click.stop="
                    openContactPopover({
                      type: 'supplier',
                      payload: { worksAt: supplier?.id, businessId: business?.id },
                    })
                  "
                >
                  <h3>{{ supplier?.name }}</h3>
                </Button>
              </template>
            </el-popover>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.allocationTask.details.reconciliationsInfo') }}</p>
            <Button
              v-for="reconciliation in reconciliations"
              :key="reconciliation.id"
              type="link"
              class="text-typography-primary p-0 text-decoration-underline"
              @click="handleReconciliationDateClick(reconciliation.id)"
            >
              {{ formatReconciliationDate(reconciliation.periodStart, reconciliation.periodEnd) }}
            </Button>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.allocationTask.details.comments') }}</p>
            <p>{{ taskComment }}</p>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">{{ $t('tasks.allocationTask.details.reconciliationSettings') }}</p>
            <div class="d-flex gap-2 align-items-center">
              <div v-if="reconciliationTemplate.note">
                <p v-if="reconciliationTemplate.note.length < 80">{{ reconciliationTemplate.note }}</p>
                <p v-else style="width: 552px">
                  <TruncatedText>{{ reconciliationTemplate.note }}</TruncatedText>
                </p>
              </div>
              <div>
                <Button type="link" class="text-typography-primary p-0 link" @click="openReconciliationSettings"
                  >{{ $t('tasks.allocationTask.details.showSettings') }}
                </Button>
              </div>
            </div>
          </div>
          <div class="d-flex gap-3 mt-4 align-items-center">
            <p class="detail">
              {{ $t('tasks.allocationTask.details.taxThreshold') }}
              <el-tooltip :content="$t('tasks.allocationTask.details.taxThresholdTooltip')" placement="top">
                <QuestionMarkFullIcon :size="16" />
              </el-tooltip>
            </p>
            <p>{{ taxThreshold }}</p>
          </div>
        </div>
        <div class="d-flex gap-6">
          <div class="flex-1">
            <div v-if="document">
              <div class="headline-text mb-5">
                {{ $t(`document.exports.schema.type.shortName.${document.type}`) }}
                {{ document.documentNumber }}
              </div>
              <div class="document-details px-4 d-flex justify-content-between align-items-center mb-3 gap-3">
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('commons.reference') }}:</span>
                  <span class="text-underline-hover text-primary" @click="handleDocumentRefClick(document.id)">
                    {{ $t(`document.exports.schema.type.shortName.${document.type}`) }}
                    {{ document.documentNumber }}
                  </span>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('commons.date') }}:</span>
                  <span>{{ formatDateNumbers(document.issueDate || document.deliveryDate) }}</span>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('commons.total') }}:</span>
                  <span>{{
                    formatCentsToCurrency(missingAllocationBilling.totalAmount, { minimumFractionDigits: 0 }) ?? '-'
                  }}</span>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <span class="labelline-text">{{ $t('tasks.allocationTask.document.taxAmount') }}:</span>
                  <span>{{
                    formatCentsToCurrency(missingAllocationBilling.taxAmount, { minimumFractionDigits: 0 }) ?? '-'
                  }}</span>
                </div>
                <div v-if="isManualDocument" class="d-flex align-items-center gap-2">
                  <PencilTrailIcon />
                  <span>{{ $t('tasks.allocationTask.document.manualDocument') }}</span>
                </div>
              </div>
              <div class="document-viewer">
                <PdfViewer v-if="document" :src="document.filePathUrl" />
              </div>
            </div>
          </div>
          <div class="decision flex-1">
            <div v-if="!loading">
              <div class="headline-text mb-5">
                {{ $t('tasks.allocationTask.decision.title') }}
              </div>

              <div v-if="document" class="actions">
                <div class="d-flex flex-column gap-3">
                  <div
                    class="d-flex flex-column gap-1 mb-2"
                    :class="{
                      'validation-error': allocationToAssignInvalid || allocationToAssignEmpty,
                      disabled: !isManualDocument,
                    }"
                  >
                    <div class="labelline-text">{{ $t('tasks.allocationTask.decision.allocationNumber') }}</div>
                    <el-input v-model="allocationNumberToAssign" :disabled="!isManualDocument" />

                    <span v-if="allocationToAssignEmpty" class="error-message">
                      {{ $t(VALIDATION_ERRORS.ALLOCATION_NUMBER_EMPTY) }}
                    </span>
                    <span v-else-if="allocationToAssignInvalid" class="error-message">
                      {{ $t(VALIDATION_ERRORS.ALLOCATION_NUMBER_FORMAT) }}
                    </span>
                    <span v-else class="action-footer">{{
                      $t('tasks.allocationTask.decision.allocationInstructions')
                    }}</span>
                  </div>
                  <span v-if="!isManualDocument">
                    <InformationIcon />
                    {{ $t('tasks.allocationTask.decision.allocationInformation') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #play-mode-indicator>
        <slot name="play-mode-indicator"></slot>
      </template>
    </SingularTaskLayout>

    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
    <ReconciliationModal
      v-if="pickedReconciliationId"
      :reconciliation-id="pickedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="handleChatOpen"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="business"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { isNil, isEmpty, either } from 'ramda';
import Big from 'big.js';

import { Button, TruncatedText } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import {
  useReconciliationTemplate,
  useReconciliations,
  ReconciliationModal,
  ReconciliationSettingsModal,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
} from '@/modules/reconciliation';
import { DocumentModal } from '@/modules/documentModal';
import { useBillings } from '@/modules/bookkeeping/composition/billing';
import { useDocumentNew2 } from '@/modules/documentModal';
import { ArrowLeftIcon, QuestionMarkFullIcon, PencilTrailIcon, InformationIcon } from '@/assets/icons';
import { useSupplier } from '@/modules/suppliers';
import { ContactCard } from '@/modules/contact';
import { useChatModal } from '@/modules/chatModal';
import { options } from '@/locale/dateConfig';
import { PdfViewer } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { SingularTaskLayout } from './components';
import { useBillingEventReferences, useBusinessById } from './composition';

const VALIDATION_ERRORS = {
  ALLOCATION_NUMBER_EMPTY: 'tasks.allocationTask.decision.allocationNumberEmpty',
  ALLOCATION_NUMBER_FORMAT: 'tasks.allocationTask.decision.allocationNumberInvalid',
  ALLOCATION_NUMBER_REQUIRED: 'tasks.allocationTask.decision.allocationNumberRequired',
  ALLOCATION_REGULATORY_REQUIRED: 'tasks.allocationTask.decision.allocationRegulatoryRequired',
};

export default {
  name: 'AllocationTask',
  components: {
    SingularTaskLayout,
    ContactCard,
    Button,
    TruncatedText,
    ArrowLeftIcon,
    QuestionMarkFullIcon,
    PencilTrailIcon,
    InformationIcon,
    ReconciliationModal,
    ReconciliationSettingsModal,
    DocumentModal,
    PdfViewer,
  },
  props: {
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
  },
  emits: ['on-task-complete', 'on-task-skip'],
  setup(props) {
    const { token } = useTenancy();
    const currentTask = computed(() => props.task);

    const { updateEventReferences } = useBillingEventReferences();
    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: [currentTask.value?.data?.billingId],
        businessId: currentTask.value?.businessId,
      }))
    );

    const missingAllocationBilling = computed(() => billings.value?.[0]);

    const { reconciliations, loading: reconciliationsLoading } = useReconciliations(
      computed(() => {
        const { date } = missingAllocationBilling.value || {};

        return {
          businessId: missingAllocationBilling.value?.businessId,
          supplierId: missingAllocationBilling.value?.supplier.id,
          fromPeriodDate: date && DateTime.fromJSDate(new Date(date)).startOf('month').toISODate(),
          toPeriodDate: date && DateTime.fromJSDate(new Date(date)).endOf('month').toISODate(),
        };
      }),
      computed(() => ({
        enabled: !!missingAllocationBilling.value,
      }))
    );

    const {
      reconciliationTemplate,
      refetch: reconciliationTemplateRefetch,
      loading: reconciliationTemplateLoading,
    } = useReconciliationTemplate(
      computed(() => ({
        businessId: currentTask.value?.businessId,
        supplierId: currentTask.value?.data?.supplierId,
      }))
    );
    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const { document, loading: documentLoading } = useDocumentNew2(
      computed(() => {
        const regulatoryEventReferenceIndex = missingAllocationBilling.value?.eventReferences.findIndex(
          (eventReference) => eventReference.regulatory
        );

        return { id: missingAllocationBilling.value?.eventReferences?.[regulatoryEventReferenceIndex]?.documentId };
      }),
      computed(() => ({
        enabled: !!missingAllocationBilling.value?.eventReferences?.[0]?.documentId,
        fetchPolicy: 'cache-first',
      }))
    );
    const { supplier, loading: supplierLoading } = useSupplier(computed(() => currentTask.value?.data?.supplierId));
    const { business, loading: businessLoading } = useBusinessById(computed(() => currentTask.value?.businessId));

    const { closeChatModal, openChatModal } = useChatModal();
    const { formatCentsToCurrency, formatToCurrency } = useCurrency();

    const loading = computed(
      () =>
        props.isLoadingTask.value ||
        billingsLoading.value ||
        reconciliationsLoading.value ||
        reconciliationTemplateLoading.value ||
        documentLoading.value ||
        supplierLoading.value ||
        businessLoading.value
    );

    return {
      loading,
      token,
      business,
      supplier,
      currentTask,
      document,
      missingAllocationBilling,
      reconciliations,
      reconciliationTemplate,
      formatToCurrency,
      formatCentsToCurrency,
      reconciliationTemplateRefetch,
      closeChatModal,
      openChatModal,
      createReconciliationTemplate,
      updateReconciliationTemplate,
      updateEventReferences,
    };
  },
  data() {
    return {
      contactUser: null,
      contactPopover: false,
      displayedDocumentId: null,
      reconciliationTemplateParams: null,
      pickedReconciliationId: null,
      allocationNumberToAssign: null,
      allocationNumberErrors: [],
      VALIDATION_ERRORS,
    };
  },
  computed: {
    isManualDocument() {
      return this.document?.isManual;
    },
    taskComment() {
      return this.currentTask.data?.comment ?? '';
    },
    taxThreshold() {
      if (this.loading) {
        return '-';
      }

      const { year } = DateTime.fromJSDate(new Date(this.missingAllocationBilling.date));

      if (isNil(year) || year > 2028 || year < 2024) {
        return '-';
      }

      const taxedAmountByYear = {
        2024: new Big(25000).times(0.17).toNumber(),
        2025: new Big(20000).times(0.17).toNumber(),
        2026: new Big(15000).times(0.17).toNumber(),
        2027: new Big(10000).times(0.17).toNumber(),
        2028: new Big(5000).times(0.17).toNumber(),
      };

      return this.formatToCurrency(taxedAmountByYear[year], { minimumFractionDigits: 0 });
    },
    isSupplierTemplateDefined() {
      return !!this.reconciliationTemplate?.supplierId;
    },
    allocationToAssignEmpty() {
      return this.allocationNumberErrors.some((e) => e === VALIDATION_ERRORS.ALLOCATION_NUMBER_EMPTY);
    },
    allocationToAssignInvalid() {
      return this.allocationNumberErrors.some((e) => e === VALIDATION_ERRORS.ALLOCATION_NUMBER_FORMAT);
    },
  },
  methods: {
    async validateTaskHook() {
      const warnings = [],
        errors = [];
      this.allocationNumberErrors = [];
      const requireCheck = either(isNil, isEmpty)(this.allocationNumberToAssign);
      const formatCheck = this.allocationNumberToAssign ? /^\d{9}$/.test(this.allocationNumberToAssign) : false;

      if (!this.isManualDocument) {
        errors.push(this.$t(VALIDATION_ERRORS.ALLOCATION_NUMBER_REQUIRED));
      } else {
        if (requireCheck) {
          errors.push(this.$t(VALIDATION_ERRORS.ALLOCATION_NUMBER_EMPTY));
          this.allocationNumberErrors.push(VALIDATION_ERRORS.ALLOCATION_NUMBER_EMPTY);
        } else if (!formatCheck) {
          errors.push(this.$t(VALIDATION_ERRORS.ALLOCATION_NUMBER_FORMAT));
          this.allocationNumberErrors.push(VALIDATION_ERRORS.ALLOCATION_NUMBER_FORMAT);
        } else {
          const regulatoryEventReferenceIndex = this.missingAllocationBilling.eventReferences.findIndex(
            (eventReference) => eventReference.regulatory
          );

          if (regulatoryEventReferenceIndex === -1) {
            errors.push(this.$t(VALIDATION_ERRORS.ALLOCATION_REGULATORY_REQUIRED));
          } else {
            try {
              await this.updateEventReferences({
                billingId: this.missingAllocationBilling.id,
                index: regulatoryEventReferenceIndex,
                updateParams: {
                  allocationNumber: this.allocationNumberToAssign,
                },
              });
            } catch (error) {
              errors.push(error);
            }
          }
        }
      }

      return { warnings, errors };
    },
    async completedTask() {
      this.$emit('on-task-complete');
    },
    skipTask() {
      this.$emit('on-task-skip');
    },
    createRequest() {
      console.log('createRequest');
    },
    handleReconciliationDateClick(id) {
      this.pickedReconciliationId = id;
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    openReconciliationTemplateModal() {},
    openContactPopover(user) {
      this.contactPopover = true;
      this.contactUser = user;
    },
    onClickOutside() {
      this.contactPopover = false;
    },
    formatReconciliationDate(periodStart, periodEnd) {
      if (!periodStart || !periodEnd) return '';
      if (periodStart === periodEnd)
        return new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(periodStart).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
      });
    },
    openReconciliationSettings() {
      this.reconciliationTemplateParams = {
        supplierId: this.supplier.id,
        template: this.reconciliationTemplate,
      };
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },

    handleChatOpen({ supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily }) {
      const formattedPeriod = this.formatDateShort(reconciliationPeriod, isDaily);
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        reconciliationId,
        formattedPeriod,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: 'numeric',
      });
    },
    handleOpenDocument(documentId) {
      this.displayedDocumentId = documentId;
    },
    formatDateNumbers(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
      });
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value, { minimumFractionDigits: 0 }) ?? '-';
    },
    handleDocumentRefClick(id) {
      this.displayedDocumentId = id;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$secondary: #94989f;
$color-neutral: #f3f3f4;
$validation-error: #cc2d4b;

.details-container {
  border: 1px solid $gray-outline;
  border-radius: 4px;
}

.decision {
  .el-input {
    width: 275px;
  }
  button {
    width: 105px;
  }

  :deep(.radio-group .el-radio__label) {
    color: $black;
  }

  .index {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid $gray-outline;
    font-size: 14px;
  }

  .actions {
    font-size: 14px;
    .action-footer {
      color: $secondary;
    }
  }
  .actions .disabled {
    color: $typography-disabled;
    button {
      color: $secondary;
      background-color: $color-neutral;
    }
    .action-footer {
      color: $typography-disabled;
    }
  }

  .validation-error {
    .error-message {
      color: $validation-error;
    }

    .el-input {
      width: 265px;
      :deep(input) {
        border-color: $validation-error;
      }
    }
  }
}

.detail {
  color: $typography-secondary;
  width: 160px;
  min-width: 160px;
}

.link {
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

.headline-text {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1rem;
}

.labelline-text {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
}

.document-details {
  width: 100%;
  height: 52px;
  background: #f8fafb;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  font-size: 14px;
}

.text-underline-hover {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.document-viewer {
  height: 600px;
}

.arrow-left-icon {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
}

.questionmark-icon {
  width: 16px;
  height: 16px;
  margin: 0px 5px;
}

:deep(p.d-inline-block.position-absolute) {
  white-space: pre-line !important;
}
</style>
